<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
  <div class="container-fluid">
      <div class="navbar-header">
          <!-- <h6>{{environmenturl}}</h6> -->
          <h6 *ngIf="environmenturl === 'https://7fgfox6kl5.execute-api.us-east-1.amazonaws.com/dev'">Development Environment</h6>

          <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
          </button>
          <span class="d-lg-none navbar-right navbar-collapse-toggle">
              <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                  <i class="ft-more-vertical"></i>
              </a>
          </span>
         
      </div>
      <div class="navbar-container">
          <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
              <ul class="navbar-nav">
                  <!-- <li class="nav-item" ngbDropdown [placement]="placement">
                      <a class="nav-link position-relative" id="dropdownBasic2" ngbDropdownToggle>
                          <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
                          <span class="notification badge badge-pill badge-danger">4</span>
                          <p class="d-none">Notifications</p>
                      </a>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown dropdown-menu-right">
                          <div class="noti-list">
                              <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                  <i class="ft-bell info float-left d-block font-large-1 mt-1 mr-2"></i>
                                  <span class="noti-wrapper">
                                      <span class="noti-title line-height-1 d-block text-bold-400 info">New Order Received</span>
                                      <span class="noti-text">Lorem ipsum dolor sit ametitaque in, et!</span>
                                  </span>
                              </a>
                              <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                  <i class="ft-bell warning float-left d-block font-large-1 mt-1 mr-2"></i>
                                  <span class="noti-wrapper">
                                      <span class="noti-title line-height-1 d-block text-bold-400 warning">New User Registered</span>
                                      <span class="noti-text">Lorem ipsum dolor sit ametitaque in </span>
                                  </span>
                              </a>
                              <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                  <i class="ft-bell danger float-left d-block font-large-1 mt-1 mr-2"></i>
                                  <span class="noti-wrapper">
                                      <span class="noti-title line-height-1 d-block text-bold-400 danger">New Order Received</span>
                                      <span class="noti-text">Lorem ipsum dolor sit ametest?</span>
                                  </span>
                              </a>
                              <a class="dropdown-item noti-container py-3">
                                  <i class="ft-bell success float-left d-block font-large-1 mt-1 mr-2"></i>
                                  <span class="noti-wrapper">
                                      <span class="noti-title line-height-1 d-block text-bold-400 success">New User Registered</span>
                                      <span class="noti-text">Lorem ipsum dolor sit ametnatus aut.</span>
                                  </span>
                              </a>
                          </div>
                          <a class="noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1">Read All Notifications</a>
                      </div>
                  </li> -->
                  <li class="nav-item" ngbDropdown [placement]="placement">
                      <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                          <i class="ft-user font-medium-3 blue-grey darken-4"></i>
                          <p class="d-none">User Settings</p>
                      </a>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left dropdown-menu-right">                           
                          <a class="dropdown-item py-1" routerLink="/profile">
                              <i class="ft-edit mr-2"></i>
                              <span>My Profile</span>
                          </a>
                          <!-- <a class="dropdown-item py-1" routerLink="/inbox">
                              <i class="ft-mail mr-2"></i>
                              <span>My Inbox</span>
                          </a>
                          <a class="dropdown-item py-1" href="javascript:;">
                              <i class="ft-settings mr-2"></i>
                              <span>Settings</span>
                          </a> -->
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="javascript:void(0)" (click)="Logout()">
                              <i class="ft-power mr-2"></i>
                              <span>Logout</span>
                          </a>
                      </div>
                  </li>
                  <!-- <li class="nav-item d-none d-lg-block">
                      <a class="nav-link position-relative notification-sidebar-toggle">
                          <i class="ft-align-left font-medium-3 blue-grey darken-4"></i>
                          <p class="d-none">Notifications Sidebar</p>
                      </a>
                  </li> -->
                 
              </ul>
          </div>
      </div>
  </div>
</nav>

<!-- Navbar (Header) Ends -->