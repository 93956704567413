import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private http: HttpClient) { }

  getMemberList = (per_page?: number, current_page?: number): Observable<HttpResponse<Object>> => {
    let perPage = per_page ? per_page : 500;
    let currentPage = current_page ? current_page : 0;
    return this.http.get(`${environment.adminUrl}/ibc-member?per_page=${perPage}&current_page=${currentPage}`, { observe: 'response' })
  }

  getMemberList2 = (param?:any): Observable<HttpResponse<Object>> => {
    return this.http.get(`${environment.adminUrl}/ibc-member?${param}`, { observe: 'response' })
  }  

  getMemberDetail = (id): Observable<any> => {
    return this.http.get<HttpResponse<Object>>(`${environment.adminUrl}/ibc-member/${id}`)
  }

  editMemberDetail = (data, id): Observable<any> => {
    return this.http.put(`${environment.adminUrl}/ibc-member/${id}`, data)
  }

  public uploadImgUrlToBackend = (data, id): Observable<any> => {
    return this.http.patch(`${environment.adminUrl}/ibc-member/${id}`, data)
  }

  createMember = (data): Observable<any> => {
    return this.http.post(`${environment.adminUrl}/ibc-member`, data)
  }

}
