import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    // {path: '/user/dashboard', title: 'Dashboard', class:'', icon: 'ft-home',  badge: '', badgeClass: '', isExternalLink: false,  submenu: []},
    // { path: '/user/members', title: 'Members', class:'', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // { path: '/user/manage-users', title: 'Manage Users', class:'', icon: 'ft-user', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // { path: '/user/fleet', title: 'Manage Fleet', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[]},
    // { path: '/user/pack', title: 'Pack', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    // { path: '/user/manage-crew', title: 'Manage Crew', icon: 'ft-anchor', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    // { path: '/user/lakes', title: 'Lakes', icon: 'ft-droplet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    // {
    //     path: '', title: 'Reservations', class: 'has-sub', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [
    //         // { path: '/user/calender', title: 'Calender', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    //         { path: '/user/calendar', title: 'Calendar', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //         { path: '/user/member-reservation', title: 'List', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },

    //=================================================
    // { path: '/user/members', title: 'Members', class: '', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/fleet', title: 'Manage Fleet', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/manage-crew', title: 'Manage Crew', icon: 'ft-anchor', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // {
    //     path: '', title: 'Reservations', class: 'has-sub', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [
    //         // { path: '/user/calender', title: 'Calender', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    //         { path: '/user/calendar', title: 'Calendar', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //         { path: '/user/member-reservation', title: 'List', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },

    // //-------------------------------------------
    // { path: '/user/dashboard', title: 'Dashboard', class: '', icon: 'ft-home', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/manage-users', title: 'Manage Users', class: '', icon: 'ft-user', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/pack', title: 'Pack', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/lakes', title: 'Lakes', icon: 'ft-droplet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    // ============================================
    // {
    //     path: '', title: 'Reservations', class: 'has-sub', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [
    //         // { path: '/user/calender', title: 'Calender', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    //         { path: '/user/calendar', title: 'Calendar View', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //         { path: '/user/member-reservation', title: 'List View', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },

    // { path: '/user/fleet', title: 'Fleet Management', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/members', title: 'Members Management', class: '', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // ----------------
    {
        path: '', title: 'Reservations', class: 'has-sub', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/reservation-calendar', title: 'Calendar View', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/reservation-list', title: 'List View', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },

    { path: '/fleet', title: 'Fleet Management', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // { path: '/boat-model', title: 'Model Management', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    { path: '/member', title: 'Members Management', class: '', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //-------------------------------------------  

    // { path: '/user/manage-crew', title: 'Crew Management', icon: 'ft-anchor', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    //-------------------------------------------
    // { path: '/user/dashboard', title: 'Dashboard', class: '', icon: 'ft-home', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/manage-users', title: 'Users Management', class: '', icon: 'ft-user', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/pack', title: 'Pack Management', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    // { path: '/user/lakes', title: 'Lakes Management', icon: 'ft-droplet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

];
