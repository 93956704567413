import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor(private http: HttpClient) { }

  getBoatModelList = (per_page?: number, current_page?: number): Observable<any> => {
    let perPage = per_page ? per_page : 500;
    let currentPage = current_page ? current_page : 0;
      return this.http.get(`${environment.adminUrl}/boat-model?per_page=${perPage}&current_page=${currentPage}`)
  }

  getBoatModelList2 = (param?:any): Observable<any> => {
    return this.http.get(`${environment.adminUrl}/boat-model?${param}`)
  }

  createBoatModel = (data): Observable<any> => {
      return this.http.post(`${environment.adminUrl}/boat-model`, data)
  }

  getBoatModelDetail = (id): Observable<any> => {
    return this.http.get(`${environment.adminUrl}/boat-model/${id}`)
  }

  editBoatModelDetail = (id,data): Observable<any> => {
    return this.http.put(`${environment.adminUrl}/boat-model/${id}`,data)
  }
  public uploadImgUrlToBackend = (data,id): Observable<any> => {
    return this.http.patch(`${environment.adminUrl}/boat-model/${id}`,data)
  }

  public deleteModel = (id: number): Observable<any> => {
    return this.http.delete(`${environment.adminUrl}/boat-model/${id}`);
  }  
}
