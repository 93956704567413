import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }


  // public login = (data): Observable<any>  => {
  //   return this.http.post(`${environment.apiUrl}/ops-login`, data);
  // }

  // public loginLog = (data): Observable<any> => {
  //   return this.http.post(`${environment.apiUrl}/cognito-user-track`, data);
  // };


   //   public getProfileDetails = () : Observable<any> => {
   //      return this.http.get(`${environment.memberUrl}/member-profile`);
   //   }

   //   public editProfileDetails = (data): Observable<any> => {
   //      return this.http.put(`${environment.memberUrl}/member-profile`, data)
   //   } 

     public uploadToS3 = (data): Observable<any> => {
        return this.http.post(`${environment.commonUrl}/s-3-presigned-url`, data)
     }

     public uploadImgUrlToBackend = (data,id): Observable<any> => {
        return this.http.patch(`${environment.adminUrl}/member-profile/${id}`,data)
     }





}

