import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  currentLang = 'en';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right'
  public isCollapsed = true;
  environmenturl=environment.adminUrl;

  constructor(public translate: TranslateService, private router: Router,
    private route: ActivatedRoute) {
      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
  }

  ChangeLanguage(language: string) {
      this.translate.use(language);
  }

  ToggleClass() {
      if (this.toggleClass === 'ft-maximize') {
          this.toggleClass = 'ft-minimize';
      }
      else
          this.toggleClass = 'ft-maximize'
  }


  Logout(){
    this.router.navigate(['/login']);
  }

}
